// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";

// Soft UI Dashboard React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "../../../../layout/LayoutContainers/PageLayout";
import curved1 from "../../../../assets/images/curved-images/stock.avif";
import coin from "../../../../assets/images/illustrations/coin.svg";
import { Icon } from "@mui/material";

function CoverLayout({ color="primary", header="", title="", description="", image, top=20, isSignInScreen=false, children }) {
  return (
    <PageLayout background="white">
      <Grid
        container
        justifyContent="center"
        sx={{
          minHeight: "98vh",
          margin: 0,
        }}
      >
        <Grid item xs={11} sm={8} md={5} xl={3}>
          
          <SoftBox mt={top}>

            <SoftBox pt={3} px={3}>
            {
              isSignInScreen && 

              <>
            <SoftBox  display="flex" alignItems="center" mb={5}>
            <SoftTypography variant="h1" sx={{
          fontSize: {
            xs: "36px",  // Extra small devices
            sm: "48px",  // Small devices
            md: "60px",  // Medium devices
            lg: "72px",  // Large devices
          },
        }} fontWeight="bold" color={color} textGradient>
                SRX 
              </SoftTypography>
              <SoftBox component="img" src={coin} alt="coin" width="80px" height="80px"  mr={2} ml={2}/>  
              <SoftTypography variant="h1" sx={{
          fontSize: {
            xs: "36px",  // Extra small devices
            sm: "48px",  // Small devices
            md: "60px",  // Medium devices
            lg: "72px",  // Large devices
          },
        }} fontWeight="bold" color={color} textGradient>
                Coin 
              </SoftTypography>
             
            </SoftBox>
            </>
            }
              {!header ? (
                <>
                  <SoftBox mb={1}>
                    <SoftTypography variant="h3" fontWeight="bold" color={color} textGradient>
                      {title}
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="body2" fontWeight="regular" color="text">
                    {description}
                  </SoftTypography>
                </>
              ) : (
                header
              )}
            </SoftBox>
            <SoftBox p={3}>{children}</SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} md={5}>
          <SoftBox
            height="100%"
            display={{ xs: "none", md: "block" }}
            position="relative"
            right={{ md: "-12rem", xl: "-17rem" }}
            mr={-16}
            sx={{
              transform: "skewX(-10deg)",
              overflow: "hidden",
              borderBottomLeftRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
            }}
          >
            <SoftBox
              ml={-12}
              height="100%"
              sx={{
                // backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                transform: "skewX(10deg)",

                backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                  `${linearGradient(
                    rgba(gradients.primary.main, 0.8),
                    rgba(gradients.primary.state, 0.6)
                  )}, url(${curved1})`,
              }}
            />
          </SoftBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}


// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
