import linearGradient from "../../../assets/theme/functions/linearGradient";

function collapseItem(theme, ownerState) {
    const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
    const { active, transparentSidenav, color } = ownerState;
  
    const { dark, white, text, transparent, info, gradients} = palette;
    const { xxl } = boxShadows;
    const { borderRadius } = borders;
    const { pxToRem } = functions;
    return {
      background: () => {
        if (active) {

          return color === "default" ? linearGradient(gradients["info"].hovered_main, gradients["info"].hovered_state) : linearGradient(gradients[color].hovered_main, gradients[color].hovered_state);
        }
  
        return transparent.main;
      },
      color: active ? dark.main : text.main,
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(16)}`,
      margin: `${pxToRem(1.8)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      cursor: "pointer",
      userSelect: "none",
      whiteSpace: "nowrap",
      boxShadow: active && transparentSidenav ? xxl : "none",
      [breakpoints.up("xl")]: {
        boxShadow: () => {
          if (active) {
            return transparentSidenav ? xxl : "none";
          }
  
          return "none";
        },
        transition: transitions.create("box-shadow", {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.shorter,
        }),
      },

      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: palette[color].hovered,
        color: dark.main,
        "& .MuiListItemIcon-root": {
          background: linearGradient(gradients[color].main, gradients[color].state),
          "& svg, svg g": {
            fill: white.main,
          },
      },
      },
    };
  }
  
  function collapseIconBox(theme, ownerState) {
    const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
    const { active, transparentSidenav, color } = ownerState;
  
    const { white, info, light, gradients } = palette;
    const { md } = boxShadows;
    const { borderRadius } = borders;
    const { pxToRem } = functions;
  
    return {
      background: () => {
        if (active) {
          return color === "default" ? info.main : palette[color].main;
        }
  
        return light.main;
      },
      minWidth: pxToRem(32),
      minHeight: pxToRem(32),
      borderRadius: borderRadius.md,
      display: "grid",
      placeItems: "center",
      boxShadow: md,
      transition: transitions.create("margin", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
  
      [breakpoints.up("xl")]: {
        background: () => {
          let background;
  
          if (!active) {
            background = transparentSidenav ? white.main : light.main;
          } else if (color === "default") {
            background = info.main;
          } else if (color === "warning") {
            background = gradients.warning.main;
          } else {
            background = linearGradient(gradients[color].main, gradients[color].state);
          }
  
          return background;
        },
      },



      "& svg, svg g": {
        fill: active ? white.main : gradients.dark.state,
      },
    };
  }
  
  const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
    color: active ? white.main : gradients.dark.state,
  });
  
  function collapseText(theme, ownerState) {
    const { typography, transitions, breakpoints, functions } = theme;
    const { miniSidenav, transparentSidenav, active } = ownerState;
  
    const { size, fontWeightMedium, fontWeightRegular } = typography;
    const { pxToRem } = functions;
  
    return {
      marginLeft: pxToRem(12.8),
  
      [breakpoints.up("xl")]: {
        opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
        maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
        marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(12.8),
        transition: transitions.create(["opacity", "margin"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
      },
  
      "& span": {
        fontWeight: active ? fontWeightMedium : fontWeightRegular,
        fontSize: size.sm,
        lineHeight: 0,
      },
    };
  }
  
  export { collapseItem, collapseIconBox, collapseIcon, collapseText };
  