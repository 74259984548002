import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, Timestamp, collection, addDoc, query, getDocs } from "firebase/firestore";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";
import SoftInput from "../../../components/SoftInput";
import DashboardLayout from "../../../layout/LayoutContainers/DashboardLayout";
import Header from "../../../components/Header";
import Loader from '../../../components/Loader';
import ProfileInfoCard from '../../../components/Cards/InfoCards/ProfileInfoCard';
import { Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import toast from 'react-hot-toast';
import { getUserDataById } from '../../../utils/fetchUserData';
function UserInfo() {
  const { userId } = useParams();
  const db = getFirestore();
  const [loading, setLoading] = useState(false);
  const [userData,setUserData] = useState(null);

  const [history, setHistory] = useState([]);

  const [userFormData, setUserFormData] = useState({
    username:  "",
    email: "",
  });

  const [formData, setFormData] = useState({
    investedAmount: 0,
    returns: 0,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "users", userId);

        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserData(data);
          setFormData({
            investedAmount: data.investedAmount || 0,
            returns: data.returns || 0,
          });
          setUserFormData({
            username: data.username || "",
            email: data.email || "",
          });
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
      setLoading(false);
    };

    async function getSelectedUsersData ( id ){
      try{
        setLoading(true)
        const data = await getUserDataById(id);
        setUserData(data);
       
        setLoading(false);
      }
      catch(error){
          setLoading(false);
          toast.error("Some error occured");
      }
  };


    fetchUserData();
    getSelectedUsersData(userId);

  }, [db, userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!formData.investedAmount || !formData.returns  || formData.investedAmount <= 0 || isNaN(formData.investedAmount) || formData.investedAmount === "" ||  isNaN(formData.returns) || formData.returns === "") {
      toast.error("Please enter valid values!");
      setLoading(false);
      return;
    }

    try {
      const docRef = doc(db, "users", userId);
      const historyCollectionRef = collection(db, "history", userId, "history");

      await updateDoc(docRef, {
        investedAmount: formData.investedAmount,
        returns: formData.returns,
      });
      
      const newHistoryItem = {
        userId: userId,
        investedAmount: formData.investedAmount,
        returns: formData.returns,
        updatedAt: Timestamp.now()
      };

      const docRefHistory = await addDoc(historyCollectionRef, newHistoryItem);
      setHistory([...history, { id: docRefHistory.id, ...newHistoryItem }]);

      setUserData((prevData) => ({
        ...prevData,
        ...formData
      }));


      toast.success("User data updated successfully!");
    } catch (error) {
      console.error("Error updating user data: ", error);
      toast.error("Something went wrong, please try again!");
    }
    setLoading(false);
  };



  const handleUserDataSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!userFormData.username) {
      toast.error("Username is required.");
      setLoading(false);
      return;
    }

    try {
      const docRef = doc(db, "users", userId);


      await updateDoc(docRef, {
        username: userFormData.username,
        email: userFormData.email,
      });
      
      setUserData((prevData) => ({
        ...prevData,
        ...userFormData
      }));


      toast.success("User data updated successfully!");
    } catch (error) {
      console.error("Error updating user data: ", error);
      toast.error("Something went wrong, please try again!");
    }
    setLoading(false);
  };


  return (
    <DashboardLayout>
      {loading && <Loader />}
      <Header />
      <SoftBox mt={5} mb={3}>
        <Grid  container spacing={3}>
          <Grid item xl={12} xs={12} >
            <ProfileInfoCard
              title="profile information"
              description=""
              info={{
                Username: userData?.username || "N/A",
                Mobile: userData?.phone || "N/A",
                Email: userData?.email || "N/A",
              }}
              isAdmin= {userData?.isAdmin}
              social={[]}
              action={{ route: "", tooltip: "Edit Profile" }}
              />
             
          </Grid>

          <Grid item xl={6} xs={12} >

          <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Update User Information
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold"
            
            
            

            >
             Username
            </SoftTypography>
          </SoftBox>
          
              <SoftInput placeholder="Username"
              
              type="text"
              label="Username"
              name="username"
              value={userFormData.username || ""}
              onChange={handleUserDataChange}
              />
            </SoftBox>
            <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
              <SoftInput placeholder="email"      
              type="text"
              label="Email"
              name="email"
              value={userFormData.email || ""}
              onChange={handleUserDataChange}
              />
            </SoftBox>

            

            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="primary" fullWidth onClick={handleUserDataSubmit}>
                Update
              </SoftButton>
            </SoftBox>

          </SoftBox>
        </SoftBox>
        
      </Card>

      

          </Grid>

          <Grid item xl={6} xs={12} >
        <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Update Investment Information
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold"
            
            
            

            >
             Invested Amount
            </SoftTypography>
          </SoftBox>
          
              <SoftInput placeholder="Invested Amount"
              
              type="text"
              label="Invested Amount"
              name="investedAmount"
              value={formData.investedAmount}
              onChange={handleChange}
              />
            </SoftBox>
            <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Returns
            </SoftTypography>
          </SoftBox>
              <SoftInput placeholder="returns"      
              type="text"
              label="Returns"
              name="returns"
              value={formData.returns}
              onChange={handleChange}
              />
            </SoftBox>

            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="primary" fullWidth onClick={handleSubmit}>
                Update
              </SoftButton>
            </SoftBox>

          </SoftBox>
        </SoftBox>
        
      </Card>
</Grid>

          {/* <Grid item xl={6} xs={12} >
          <Card>
              <SoftBox p={3} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  History
                </SoftTypography>
              </SoftBox>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Invested Amount</TableCell>
                      <TableCell>Returns</TableCell>
                      <TableCell>Updated At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {history.map((entry) => (
                      <TableRow key={entry.id}>
                        <TableCell>{entry.investedAmount}</TableCell>
                        <TableCell>{entry.returns}</TableCell>
                        <TableCell>{entry.updatedAt.toDate().toLocaleString()}</TableCell>
                        <TableCell>  {new Date(entry.updatedAt?.seconds * 1000).toLocaleDateString('en-US', {
                    weekday: 'long',
                   
                  })}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
</Grid> */}

        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default UserInfo;
