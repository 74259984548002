// Soft UI Dashboard React components
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";

import PhoneInput from "react-phone-input-2";
import CoverLayout from "../components/CoverLayout";
// Images
import {auth} from "../../../config/firebase"
import curved9 from "../../../assets/images/curved-images/curved-6.jpg";
import { useState } from "react";
import "./sign-in.scss";
// import 'react-phone-input-2/lib/style.css';
import OtpInput from "otp-input-react";
import { RecaptchaVerifier } from "firebase/auth";
import toast from 'react-hot-toast';
import {  getAdditionalUserInfo  } from 'firebase/auth';
import { UserContext } from '../../../context/userContext';
import { signInWithPhoneNumber } from "firebase/auth";
import {getFirestore, setDoc, doc} from "firebase/firestore";
import { useContext } from "react";
import Loader from "../../../components/Loader";

function SignIn() {

  const [showOtp,setShowOtp] = useState(false);
  const [phone,setPhone] = useState('');
  const [otp,setOtp] = useState('');
  const [loading,setLoading] = useState(false);
  const { userState, userDispatch } = useContext(UserContext);
  const db = getFirestore();


  // signup
  function onCaptchaVerify(){

    auth.useDeviceLanguage();
    if(!window.recaptchaVerifier){
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
      //  onSignUp()

      },
      'expired-callback': () => {
    
      }
    });
  }

  }


function onSignUp(){

setLoading(true);
onCaptchaVerify()
const appVerifier = window.recaptchaVerifier;
const formattedPhone = "+" + phone
if(phone === ''  ||  phone.length < 12){
  toast.error("Please enter a valid phone number!!")
  setLoading(false);
  return
}
toast.success("Please wait while we send OTP to your phone number");

signInWithPhoneNumber(auth, formattedPhone, appVerifier)
.then((confirmationResult) => {
  // SMS sent. Prompt user to type the code from the message, then sign the
  // user in with confirmationResult.confirm(code).
  window.confirmationResult = confirmationResult;
  setLoading(false);
  setShowOtp(true);
  toast.success('OTP sent successfully');

}).catch((error) => {
    toast.error("Some error occured!")
    if(String(error).includes('reCAPTCHA client element has been removed')){
      window.location.reload();
    }
    setLoading(false)

});



}

  

function onOTPVerify() {

if(otp==''){
  toast.error("OTP is invalid");
  setLoading(false);
  return;
}

setLoading(true);
window.confirmationResult
  .confirm(otp)
  .then(async (res) => {
    if (getAdditionalUserInfo(res)?.isNewUser) {
    try {

      // const docRef = await setDoc(doc(db, "users", res.user.uid), {
      //   id: res.user.uid,
      //   phone: "+" + phone,
      //   email: "",
      //   username: "",
      //   displayName: "",
      //   photoUrl: "",
      //   firstName: "",
      //   lastName:"",
      //   totalDepositMade: 0.0,
      //   currentValue: 0.0,
      //   profit: 0.0,
      //   withdrawReq: 0,
      //   loginMethod: "phone",
      //   isProfileUpdated: false,
      //   isKycCompleted: false,
      //   isKycDocSubmitted: false,
      //   totalReturns: 0,
      //   address: null,
      //   isAdmin: false,
      //   gender: '',
      //   marital_status: '',
      //   date_of_birth: null,
      //   aadhar_card_front_url: null,
      //   aadhar_card_back_url: null,
      //   pan_card_url: null,
      //   nomineeName: null,
      //   nomineeRelation: null,
      // });
    // wont register user here, will be done by admin manually
    console.log("User created successfully!");
    } catch (e) {
        toast.error("Some error occured while creating user profile!")
      console.error("Error adding document: ", e);
      setLoading(false);
    }

  }

    setLoading(false);
  })
  .catch((err) => {
    setLoading(false);
  }).finally(()=>{
    window.location.reload();
  });
}



  return (
    <>
        {
      loading && <Loader color="primary" open={true}/>
    }
    <CoverLayout
      title="Welcome"
      description="Enter your mobile number and otp to sign in."
      image={curved9}
      isSignInScreen = {true}
    >
      <SoftBox component="form" role="form">
{
      !showOtp ? (
        <>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Mobile
            </SoftTypography>
          </SoftBox>
          <PhoneInput country={"in"} value={phone} onChange={setPhone} enableSearch={true} required></PhoneInput>
        </SoftBox>

      <SoftBox mt={4} mb={1}>
      <SoftButton variant="gradient" color="primary" fullWidth onClick={onSignUp}>
        Send OTP
      </SoftButton>
      </SoftBox>
      </>
      ) : (
        <>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
            Enter your OTP
            </SoftTypography>
          </SoftBox>
          <OtpInput  OTPLength={6} value={otp} onChange = {setOtp} otpType="number" disabled = {false} required autoFocus className="otp-container"></OtpInput>
        </SoftBox>

        <SoftBox mt={4} mb={1}>
        <SoftButton variant="gradient" color="primary" fullWidth onClick={onOTPVerify}>
          Verify OTP
        </SoftButton>
        </SoftBox>

        </>

      )}


      </SoftBox>
    </CoverLayout>

    </>
  );
}

export default SignIn;
